// Load the IFrame Player API code asynchronously.
var tag = document.createElement('script');
tag.src = "https://www.youtube.com/player_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

var firstPlayFlag = true;
var player;
var videoId = 'c_A-TKWqVV0';
var videotime = 0;
var timeupdater = null;

function mobileAndTabletcheck() {
    return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf('IEMobile') !== -1;
}

function onYouTubePlayerAPIReady() {
    player = new YT.Player('mainVideo', {
        videoId: videoId,
        playerVars: {
            'autoplay': 0,
            'host': 'https://www.youtube.com',
            'controls': 2,
            'loop': 1,
            'showinfo': 0,
            'fs': 0,
            'playsinline': 1,
            'rel': 0,
            'modestbranding': 1,
            'origin': document.location.origin
        },
        suggestedQuality: 'large',

        events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
        }
    });
}

// when the player is ready, start checking the current time every 100 ms.
function onPlayerReady() {

    function updateTime() {
        var oldTime = videotime;
        if (player && player.getCurrentTime) {
            videotime = player.getCurrentTime();
        }
        if (videotime !== oldTime) {
            onProgress(videotime);
        }
    }

    if (firstPlayFlag && !mobileAndTabletcheck()) {
        firstPlayFlag = !firstPlayFlag;
        player.mute();
        player.playVideo();

        var interval = setInterval(function () {
            if (player.getPlayerState() === 1) {
                player.pauseVideo();
                player.unMute();
                clearInterval(interval);
            }
        }, 100);
    }

    timeupdater = setInterval(updateTime, 100);

    $(".department__home").on('click', function () {
        player.pauseVideo();
        $(".department-popup").removeClass("active");
        var departmentClass = $(this).data("class");
        $("." + departmentClass).addClass("active");
    });

    $(".department-popup__close").on('click', function () {
        player.playVideo();

        $(this).closest('.department-popup').removeClass("active");
    });

    $(".home-section__button").on('click', function () {

        $(".home-section").addClass("passive");
        $(".parallax__video-container, .parallax-video_close").addClass("active");
        setTimeout(function () {
            if (!mobileAndTabletcheck()) {
                player.playVideo();
            }
        }, 1000);
    });

    $(".parallax-video_close").on("click", function () {
        player.pauseVideo();

        $(".home-section").removeClass("passive");
        $(".parallax__video-container, .parallax-video_close").removeClass("active first");
    });
}

// when the time changes, this will be called.
function onProgress(currentTime) {
    const $home = $(".department__home");
    const $pm = $(".department__home_pm");
    const $backend = $(".department__home_backend");
    const $design = $(".department__home_design");
    const $frontend = $(".department__home_frontend");
    const $qa = $(".department__home_qa");
    const $marketing = $(".department__home_marketing");

    if (currentTime <= 5.5 && $home.hasClass("shown")) {
        $home.removeClass('shown');
        return;
    }

    if (currentTime >= 5.5 && currentTime < 10 && !$pm.hasClass("shown")) {
        $pm.addClass('shown');
        return;
    }

    if (currentTime >= 10 && currentTime < 20 && !$backend.hasClass("shown")) {
        $home.removeClass('shown');
        $backend.addClass('shown');
        return;
    }

    if (currentTime >= 20 && currentTime < 30 && !$design.hasClass("shown")) {
        $home.removeClass('shown');
        $design.addClass('shown');
        return;
    }

    if (currentTime >= 30 && currentTime < 41 && !$frontend.hasClass("shown")) {
        $home.removeClass('shown');
        $frontend.addClass('shown');
        return;
    }

    if (currentTime >= 41 && currentTime < 51.7 && !$qa.hasClass("shown")) {
        $home.removeClass('shown');
        $qa.addClass('shown');
        return;
    }

    if (currentTime >= 51.7 && currentTime < 62 && !$marketing.hasClass("shown")) {
        $home.removeClass('shown');
        $marketing.addClass('shown');
        return;
    }

    if (currentTime >= 62 && $marketing.hasClass("shown")) {
        $marketing.removeClass('shown');
    }
}

//when end of video
function onPlayerStateChange(event) {
    if (event.data === 0) {
        $(".home__video-play").fadeIn();
        $(".home-section").removeClass("passive");
        $(".parallax__video-container, .parallax-video_close").removeClass("active");
    }
}

document.addEventListener('DOMContentLoaded', function () {

    if (window.hideYTActivated) return;

    if (typeof YT === 'undefined') {
        let tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    let onYouTubeIframeAPIReadyCallbacks = [];
    for (let playerWrap of document.querySelectorAll(".hytPlayerWrap")) {
        let playerFrame = playerWrap.querySelector("iframe");
        let onPlayerStateChange = function (event) {

            if (event.data == YT.PlayerState.ENDED) {
                playerWrap.classList.add("general", "ended");
            } else if (event.data == YT.PlayerState.PLAYING) {
                playerWrap.classList.remove("general", "ended");
            }
        };

        let player;
        onYouTubeIframeAPIReadyCallbacks.push(function () {
            player = new YT.Player(playerFrame, {events: {'onStateChange': onPlayerStateChange}});
        });

        playerWrap.addEventListener("click", function () {
            let playerState = player.getPlayerState();

            if (playerState == YT.PlayerState.ENDED) {
                player.seekTo(0);
            } else if (playerState == YT.PlayerState.PAUSED) {
                player.playVideo();
            }
        });
    }
    window.onYouTubeIframeAPIReady = function () {

        for (let callback of onYouTubeIframeAPIReadyCallbacks) {
            callback();
        }
    };
    window.hideYTActivated = true;
});